html {
  background-image: linear-gradient(to bottom right, #f7f4dd, #adab96);
  font-size: 10px;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow: hidden;
}

img {
  max-height: 1em;
  max-width: 1em;
}

@keyframes welcomeGrow {
  0% {
    font-size: 10px;
  }

  100% {
    font-size: 13vmin;
  }
}

@keyframes diggingPulse {
  0%, 100% {
    font-size: 13vmin;
  }

  30% {
    font-size: var(--dig_amp);
  }
}

@keyframes infiniteSpin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

#visualizer {
  width: 100vw;
  height: 100vh;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
}

.lineup {
  min-height: 100vh;
  --dig_t: 1s;
  --flip_t: 2s;
  --dig_amp: 14vmin;
  z-index: 0;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  animation: 1s ease-out forwards welcomeGrow;
  display: flex;
}

.fellow {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

.digging.flipping {
  animation: diggingPulse var(--dig_t) ease-out 0s infinite both, infiniteSpin var(--flip_t) 1;
}

.digging {
  animation: diggingPulse var(--dig_t) ease-out 0s infinite both;
}

.flipping {
  animation: infiniteSpin var(--flip_t) 1;
}

#user-message {
  width: 50%;
  text-align: center;
  color: #252521;
  font-size: large;
  position: fixed;
  bottom: 3%;
  right: 25%;
}

/*# sourceMappingURL=index.593694dc.css.map */
